import { Injectable } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { MatIconRegistry } from "@angular/material/icon";

@Injectable({
  providedIn: "root",
})
export class IconService {
  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {}

  public registerIcons(): void {
    this.loadIcon("lock-icon", "lock-icon.svg");
    this.loadIcon("unlock-icon", "unlock-icon.svg");
    this.loadIcon("map-view", "map-view.svg");

    // add more icons here...
  }

  private loadIcon(iconName: string, iconUrl: string): void {
    this.matIconRegistry.addSvgIcon(
      iconName,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/images/${iconUrl}`)
    );
  }
}
